import React from 'react';
import Layout from "../layouts/index";
import '../style/index.scss';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import i18next from 'i18next';
import * as JsSearch from 'js-search';
import Carousel from "react-slick";
import ProductTile from '../components/productTile';
import { GlobalContext } from '../context/globalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import AccordionMenu from '../components/accordion_menu';
import SEO from '../components/seo';
import { getStock } from '../api/snipcart';

let search = '';
let noImage = `${ process.env.GATSBY_NO_IMAGE_URL}`;

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className="slick-prev slick-arrow"
      aria-hidden="true"
      type="button"
    >
      Previous
    </button>
  );
  
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className="slick-next slick-arrow"
      aria-hidden="true"
      type="button"
    >
      Next
    </button>
  );


const settings = {
    dots: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const settingsOtherColors = {
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }
    ]
};

const settingsProduct = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300
}

class ProductPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            searchOtherColors: '',
            searchOtherSizes: '',
            isColorsClosed: false,
            zoomImage: noImage,
            isZoomOpen: false,
            crumbsArray: [],
            noProductImage: '',
            hasStock: false
        }
    }

    componentDidMount() {
        const data = this.props.data;
        search = new JsSearch.Search('id');
        search.indexStrategy = new JsSearch.ExactWordIndexStrategy();
        search.addIndex('marca');
        search.addIndex('modelo');
        search.addIndex('idarticulo');
        data.allDatoCmsProduct.edges.map(({ node: product }) => {            
            return search.addDocument(product);
        });        

        let crumbArray = this.props.pageContext.breadcrumb.crumbs;
        let categoryObject = data.product.categorias[0];
        let categoryURL = categoryObject.url;
        let categoryName = categoryObject.name;

        let categoryInfo = {
            pathname: "/" + this.props.pageContext.locale + "/" + categoryURL, 
            crumbLabel: categoryName
        };
        
        if(crumbArray[crumbArray.length - 2].crumbLabel !== categoryName) {
            crumbArray.splice(crumbArray.length - 1, 0, categoryInfo);
        }

        let searchExecution = search.search(data.product.modelo);
        let searchSizes = [];
        searchSizes.push(data.product);
        searchExecution.forEach((prod) => {
            if (data.product.modelo === prod.modelo && data.product.marca === prod.marca && data.product.colorcrist === prod.colorcrist
                && data.product.colormont === prod.colormont && data.product.material === prod.material
                && data.product.calibre !== prod.calibre) {
                    searchSizes.push(prod);
            }
        });

        this.setState({
            searchOtherColors: searchExecution,
            searchOtherSizes: searchSizes,
            selectedSize: data.product.idarticulo,
            crumbsArray: crumbArray
        }, this.checkStock);

        if (document.getElementById('countrySelect')) {
            if (document.getElementById('countrySelect').value === 'eu') {
                document.getElementsByClassName("pdp-product__taxes-included")[0].style.display = 'block';
            } else {
                document.getElementsByClassName("pdp-product__taxes-included")[0].style.display = 'none';
            }
        }
    }
    
    /**
     * @function toggleColors
     * @desc this function open and close the available colors section
     */
    toggleColors = () => {
        document.querySelector('.pdp-product__colors').classList.toggle('closed');
        this.setState({
            isColorsClosed: !this.state.isColorsClosed
        });
    }

    /**
     * @function addNoImage
     * @desc this function set the default image to a product when there is no image
     * or when the url is broken
     * @param {*} e  - element
     */
    addNoImage = (e) => {
        e.target.src = noImage;
        if(e.target.parentElement.classList.contains('pdp-product__image-wrapper')) {
            this.setState({
                noProductImage: noImage
            });
        }
    }

    /**
     * @function setAutoPlayToCarousel
     * @desc this function set autoplay to the carousel settings depending of the array size
     * @param {*} crosssell  - cross sell elements array
     */
    setAutoPlayToCarousel = (crosssell) => {
        if(crosssell.length >= settings.slidesToShow) {
            settings.infinite = true;
            settings.autoplay = true;
        } else {
            settings.infinite = false;
            settings.autoplay = false;
        }
        settings.responsive.map(breakpoint => {
            if(breakpoint.settings.slidesToShow <= crosssell.length) {
                breakpoint.settings.autoplay = true;
                breakpoint.settings.infinite = true;
                return '';
            } else {
                breakpoint.settings.autoplay = false;
                breakpoint.settings.infinite = false;
                return '';
            }
        });
    }

    /**
     * @function formatCurrency
     * @desc this function change the currency format into the selected currency and language 
     * 
     * @param {locale} - locale language
     * @param {currency} - currency type
     * @param {number} - price value
     */
    formatCurrency = (locale, currency, number) => {
        return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(number);
    }

    /**
     * @function getProductImages
     * @desc this function return all the product images in an array
     * 
     * @param {product} - product data object
     */
    getProductImages = (product) => {
        let imagesArray = [];
        if (product.images && product.images.split(";").length > 0) {
            imagesArray = product.images.split(";");
        }
        return imagesArray;
    }

    /**
     * @function openZoomImage
     * @desc this function open the clicked image in the zoom modal
     * 
     * @param {e}
     */
    openZoomImage = (e) => {
        var zoomImg = e.target.querySelector('img').getAttribute('data-src');
        if(zoomImg !== noImage) {
            this.setState({
                zoomImage: zoomImg,
                isZoomOpen: true
            });
        }
    }

    /**
     * @function closeZoomImage
     * @desc this function close the zoom modal
     */
    closeZoomImage = () => {
        this.setState({
            isZoomOpen: false
        });
    }

    /**
     * @function getLocaleAttributes
     * @desc this function gets the translated attribute value
     * 
     * @param {attr} - product attribute object
     */
    getLocaleAttributes = (attr) => {
        let jsonOption = JSON.parse(attr);
        let jsonOptionValue = [];
        let localeFilterOption = '';
        for(let key in jsonOption) {
            jsonOptionValue = jsonOption[key];
            jsonOptionValue = jsonOptionValue[0];
            localeFilterOption = jsonOptionValue[(this.props.pageContext.locale).toUpperCase()];
        }

        return localeFilterOption;
    }

    /**
     * @function changeSelectedSize
     * @desc this function update the id of the selected size
     * 
     */
    changeSelectedSize = (e) => {
        this.setState({
            selectedSize: e.target.value,
            hasStock: false
        }, this.checkStock);
    }

    /**
     * @function checkStock
     * @desc this function gets the translated attribute value
     * 
     */
    checkStock = async () => {
        let stock = await getStock(this.state.selectedSize);
        this.setState({
            hasStock : stock > 0
        });
    }

    render () {
        const data = this.props.data;
        const crosssell = data.product.crosssell.split(',');
        this.setAutoPlayToCarousel(crosssell);
        let offerPrice = 0;
        let listPrice = 0;
        const locale = data.product.locale;
        const productImages = this.getProductImages(data.product);
        let newBadgeConst = new Date();
        newBadgeConst.setDate(newBadgeConst.getDate() - (data.newBadgeDays && data.newBadgeDays.data && !isNaN(JSON.parse(data.newBadgeDays.data).days) ? JSON.parse(data.newBadgeDays.data).days : 30));

        return (
            <Layout children={null} data={data}>
                <SEO 
                    title={data.product.modelo}
                    keywords=''
                    slug={data.product.id}
                    description={data.product.descriptionampliada !== null ? data.product.descripcionampliada : data.product._allDescriptionLocales.filter(function (el) { return el.locale === 'en'})[0].value }
                    lang={data.product.locale}
                    image={productImages[0]}
                    contactinfo={JSON.parse(data.contactinfo.data)}
                />
                <Breadcrumb crumbs={this.state.crumbsArray} crumbSeparator=" > " hiddenCrumbs={['/en', '/es']} crumbLabel={data.product.modelo ? data.product.modelo : data.product.idarticulo} />
                <div className="pdp">
                    <div className="pdp-product" key={data.product.id}>
                        <div className="pdp-product__main">
                            <div className="pdp-product__image-carousel">
                                {
                                    data.product.meta && data.product.meta.createdAt && new Date(data.product.meta.createdAt) < newBadgeConst &&
                                    <span className="pdp-product__new-badge">{i18next.t("PDP_NEW_BADGE")}</span>
                                }
                                <Carousel {...settingsProduct} className="pdp-product__main-carousel">
                                {
                                    productImages.map((productImage,index) => {
                                        if(productImage || index === 0) {
                                            return(
                                                <div key={index} className="pdp-product__image-wrapper" onClick={this.openZoomImage}>
                                                    <img className="lazyload" onError={this.addNoImage} data-src={productImage ? productImage : noImage} alt="product"/>
                                                    <FontAwesomeIcon className="pdp-product__zoom-icon" icon="search" />
                                                </div>
                                            )
                                        } else {
                                            return '';
                                        }
                                    })
                                }
                                </Carousel>
                            </div>
                            {
                                this.state.searchOtherColors ?
                                    this.state.searchOtherColors !== -1 && (this.state.searchOtherColors.length - this.state.searchOtherSizes.length) > 0 ?
                                    <div id="productColors" className="pdp-product__colors">
                                        <div className="pdp-product__colors-title">
                                            {
                                               ((this.state.searchOtherColors.length - this.state.searchOtherSizes.length)===1) &&
                                               <h2>{(this.state.searchOtherColors.length) - this.state.searchOtherSizes.length} {i18next.t("PDP_AVAILABLE_COLOR")}</h2>
                                            }
                                            {
                                               ((this.state.searchOtherColors.length - this.state.searchOtherSizes.length)>1) &&
                                               <h2>{(this.state.searchOtherColors.length) - this.state.searchOtherSizes.length} {i18next.t("PDP_AVAILABLE_COLORS")}</h2>
                                            }
                                        </div>
                                        <Carousel {...settingsOtherColors} className="pdp-product__colors-wrapper">
                                            {
                                                this.state.searchOtherColors.map((prod, index) => {
                                                    if(prod && prod !== undefined && prod !== null && prod !== '' && prod !== -1) {
                                                        return (
                                                            data.product.idarticulo !== prod.idarticulo ?
                                                                <Link key={index} to={'/' + data.product.locale + '/' + prod.idarticulo} title={prod.modelo}>
                                                                    <img className="lazyload" onError={this.addNoImage} data-src={prod.images && prod.images.split(";")[0] ? prod.images.split(";")[0] : noImage} alt="product"/>
                                                                </Link>
                                                            : ''
                                                        )
                                                    } else {
                                                        return '';
                                                    }
                                                })           
                                            }
                                        </Carousel>
                                    </div>
                                    : ''
                                : ''
                            }
                        </div>
                        <div className="pdp-product__aside">
                            <h1>
                                <span className="pdp-product__brand">
                                    {data.product.descripcion ? data.product.descripcion : (data.product.marca ? data.product.marca : data.product.idarticulo)}
                                </span>
                            </h1>
                            <div className="pdp-product__prices">
                                <GlobalContext.Consumer>
                                    {state => {
                                        let currency = state.currency;
                                        switch (currency) {
                                            case 'USD':
                                                offerPrice = data.product.offerpriceusd;
                                                listPrice = data.product.listpriceusd;
                                                break;
                                            case 'EUR':
                                                offerPrice = data.product.offerpriceeur;
                                                listPrice = data.product.listpriceeur;
                                                break;
                                            case 'GBP':
                                                offerPrice = data.product.offerpricegbp;
                                                listPrice = data.product.listpricegbp;
                                                break;
                                            default:
                                                offerPrice = 0;
                                                listPrice = 0;
                                                break;
                                        }
                                        return (
                                            <>
                                            {listPrice > 0 && offerPrice === listPrice &&
                                            <div className={"pdp-product__price " + (data.product.listpriceusd ? 'pdp-product__price--list' : '')}>
                                                {this.formatCurrency(locale, currency, listPrice)}
                                            </div>
                                            }
                                            {offerPrice > 0 && offerPrice < listPrice &&
                                            <div className="pdp-product__price pdp-product__price--offer">
                                                {this.formatCurrency(locale, currency, offerPrice)}
                                            </div>
                                            }
                                            </>
                                        );
                                    }}
                                </GlobalContext.Consumer>
                                <span className="pdp-product__taxes-included">{i18next.t("PDP_TAXES_INCLUDED")}</span>
                            </div>
                            { this.state.searchOtherSizes && this.state.searchOtherSizes.length > 1 &&
                                <div className={"pdp-product__sizes"}>
                                    <div><span>{i18next.t("PDP_SIZE_TITLE")}</span></div>
                                    {this.state.searchOtherSizes.map(product => {
                                        return (
                                            <div key={product.calibre}>
                                                <input
                                                    type="radio"
                                                    name="size"
                                                    id={"size" + product.calibre}
                                                    value={product.idarticulo}
                                                    onChange={this.changeSelectedSize}
                                                    checked={product.idarticulo === this.state.selectedSize}/>
                                                <label htmlFor={"size" + product.calibre} className="size-checkbox">
                                                    {i18next.t("PDP_SIZE_CALIBRE") + " " + product.calibre} - {i18next.t("PDP_SIZE_PUENTE") + " " + product.puente} - {i18next.t("PDP_SIZE_VARILLA") + " " + product.varilla}
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            { this.state.searchOtherSizes && this.state.searchOtherSizes.length > 1 ?
                                    this.state.searchOtherSizes.map(product => {
                                        return (
                                            <div id={"button-" + product.calibre} key={"button-" + product.calibre} className={"pdp-product__add-cart" + (this.state.hasStock ? "" : " disabled") + (this.state.selectedSize === product.idarticulo ? "" : " hidden")}>
                                                <button id="add2cart" className="snipcart-add-item"
                                                    data-item-id={product.idarticulo}
                                                    data-item-weight={product.weight}
                                                    data-item-price={ '{"usd": ' + product.offerpriceusd + ', "eur": ' + product.offerpriceeur + ', "gbp": ' + product.offerpricegbp + '}'}
                                                    data-item-price-list={ '{"usd": ' + (product.listpriceusd | product.offerpriceusd) + ', "eur": ' + (product.listpriceeur | product.offerpriceeur) + ', "gbp": ' + (product.listpricegbp | product.offerpricegbp) + '}'}
                                                    data-item-image={this.state.noProductImage !== '' ? this.state.noProductImage : product.images && product.images.split(";")[0] ? product.images.split(";")[0] : noImage}
                                                    data-item-name={product.modelo}
                                                    data-item-description={product.descripcionampliada ? product.descripcionampliada : product.descripcion}
                                                    data-item-url={(typeof window !== `undefined` ? window.location.origin : ``) + `/` + product.locale + `/` + product.idarticulo}
                                                    data-item-custom1-name={
                                                        i18next.t("PDP_DETAILS_SHAPE") + ': ' + (product.forma ? product.forma : '--') + ', ' +
                                                        i18next.t("PDP_DETAILS_FRAMEC") + ': ' + (product.colormont ? product.colormont : '--') + ', ' +
                                                        i18next.t("PDP_SIZE_CALIBRE") + ': ' + (product.calibre ? product.calibre : '--')
                                                    }
                                                    data-item-custom1-required="false"
                                                >
                                                    <span>{this.state.hasStock ? i18next.t("PDP_ADD2CART") : i18next.t("PDP_ADD2CART_DISABLED")}</span>
                                                </button>
                                            </div>
                                        )
                                    })
                                :
                                    <div className={"pdp-product__add-cart" + (this.state.hasStock ? "" : " disabled") }>
                                        <button id="add2cart" className="snipcart-add-item"
                                            data-item-id={data.product.idarticulo}
                                            data-item-weight={data.product.weight}
                                            data-item-price={ '{"usd": ' + data.product.offerpriceusd + ', "eur": ' + data.product.offerpriceeur + ', "gbp": ' + data.product.offerpricegbp + '}'}
                                            data-item-price-list={ '{"usd": ' + (data.product.listpriceusd | data.product.offerpriceusd) + ', "eur": ' + (data.product.listpriceeur | data.product.offerpriceeur) + ', "gbp": ' + (data.product.listpricegbp | data.product.offerpricegbp) + '}'}
                                            data-item-image={this.state.noProductImage !== '' ? this.state.noProductImage : data.product.images && data.product.images.split(";")[0] ? data.product.images.split(";")[0] : noImage}
                                            data-item-name={data.product.modelo}
                                            data-item-description={data.product.descripcionampliada ? data.product.descripcionampliada : data.product.descripcion}
                                            data-item-url={(typeof window !== `undefined` ? window.location.origin : ``) + `/` + data.product.locale + `/` + data.product.idarticulo}
                                            data-item-custom1-name={
                                                i18next.t("PDP_DETAILS_SHAPE") + ': ' + (data.product.forma ? data.product.forma : '--') + ', ' +
                                                i18next.t("PDP_DETAILS_FRAMEC") + ': ' + (data.product.colormont ? data.product.colormont : '--') + ', ' +
                                                i18next.t("PDP_SIZE_CALIBRE") + ': ' + (data.product.calibre ? data.product.calibre : '--')
                                            }
                                            data-item-custom1-required="false"
                                        >
                                            <span>{this.state.hasStock ? i18next.t("PDP_ADD2CART") : i18next.t("PDP_ADD2CART_DISABLED")}</span>
                                        </button>
                                    </div>
                            }
                            <div className="pdp-product__description">
                                <AccordionMenu 
                                    title={i18next.t("PDP_DESCRIPTION")} 
                                    content={
                                        data.product.descriptionampliada !== null ? 
                                            data.product.descripcionampliada
                                        : 
                                            data.product._allDescriptionLocales.filter(function (el) { return el.locale === 'en'})[0].value 
                                    }
                                ></AccordionMenu>
                            </div>
                            <div className="pdp-product__details">
                                <AccordionMenu 
                                    title={i18next.t("PDP_DETAILS")} 
                                    content={
                                        <>
                                            { (data.product.forma !== 'No disponible') && <p>{i18next.t("PDP_DETAILS_SHAPE")}: {data.product.forma}</p> }
                                            { (data.product.colormont !== 'No disponible') && <p>{i18next.t("PDP_DETAILS_FRAMEC")}: {data.product.colormont}</p> }
                                            { (data.product.colorcrist !== 'No disponible') && <p>{i18next.t("PDP_DETAILS_COLORC")}: {data.product.colorcrist}</p> }
                                            { (data.product.material !== 'No disponible') && <p>{i18next.t("PDP_DETAILS_MATERIAL")}: {data.product.material}</p> }
                                            { (data.product.sexo !== 'No disponible') && <p>{i18next.t("PDP_DETAILS_GENDER")}: {data.product.sexo}</p> }
                                            { (data.product.polarizacion === 'TRUE') && <p>{i18next.t("PDP_DETAILS_POLARIZED")}</p> }
                                            { this.state.searchOtherSizes && this.state.searchOtherSizes.length > 1 ?
                                                    this.state.searchOtherSizes.map(product => {
                                                        return (
                                                            (product.calibre && product.puente && product.varilla) &&
                                                                <p id={"details-" + product.calibre} key={"details-" + product.calibre} className={(this.state.selectedSize === product.idarticulo ? "" : "hidden")}>
                                                                    {i18next.t("PDP_DETAILS_MEDIDAS")}: {product.calibre} - {product.puente} - {product.varilla}
                                                                </p>
                                                        )
                                                    })
                                                :
                                                (data.product.calibre && data.product.puente && data.product.varilla) &&
                                                    <p>{i18next.t("PDP_DETAILS_MEDIDAS")}: {data.product.calibre} - {data.product.puente} - {data.product.varilla}</p>
                                            }
                                        </>
                                    }
                                ></AccordionMenu>
                            </div>
                            <div className="pdp-product__manufacturer">
                                <AccordionMenu 
                                    title={i18next.t("PDP_BRAND")} 
                                    content={
                                        data.product.marca ? data.product.marca :  data.product.idarticulo
                                    }
                                ></AccordionMenu>
                            </div>
                            <div className="pdp-product__shipping">
                                <AccordionMenu 
                                    title={i18next.t("PDP_SHIPPING")} 
                                    content={
                                        data.shipping.edges.map(({node: shippingText}, index)=> {
                                            return (<div key={index} dangerouslySetInnerHTML={{__html: shippingText.legalPageContentWrapper[0].legalPageTextContent}} />);
                                        })
                                    }
                                ></AccordionMenu>
                            </div>
                        </div>
                    </div>
                    <div className="pdp__suggestions">
                        {    
                            crosssell.length >= 1 ? 
                                <>
                                    <div className="pdp__suggestions-title">
                                        <h2>{i18next.t('PDP_SUGGESTIONS')}</h2>
                                    </div>
                                    <Carousel {...settings} className="pdp__cross-sell">
                                    {
                                        crosssell.map((crossSellProduct, index) => {
                                            if(this.state.search) {
                                                let prod = search.search(crossSellProduct);
                                                if(prod && prod !== undefined && prod !== null && prod !== '' && prod !== -1 && prod[0] && prod[0] !== undefined && prod[0] !== null ) {
                                                    return (
                                                        <ProductTile key={index} locale={data.product.locale} product={prod[0]} data={data}></ProductTile>
                                                    )
                                                } else {
                                                    return '';
                                                }
                                            } else {
                                                return '';
                                            }
                                        })           
                                    }
                                    </Carousel>
                                </>
                            : ''
                        }
                    </div>
                    <div className={this.state.isZoomOpen ? "pdp-product__zoom" : "pdp-product__zoom hide"}>
                        <div className="pdp-product__zoom-fade" onClick={this.closeZoomImage}></div>
                        <div className="pdp-product__zoom-image">
                            {
                                this.state.zoomImage &&
                                <img className="lazyload" onError={this.addNoImage} data-src={this.state.zoomImage} src={this.state.zoomImage !== noImage ? this.state.zoomImage : ''} alt="product"/>
                            }
                            <FontAwesomeIcon className="pdp-product__close-icon" icon="times-circle" onClick={this.closeZoomImage}/>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
} 

/* ProductQuery */
export const query = graphql`
query ($productId: String!,  $locale: String!) {
    product: datoCmsProduct(id: {eq: $productId }) {
        id
        descripcion
        descripcionampliada
        categoria
        categorias {
            id
            name
            url
            locale
            sequence
        }
        forma
        colormont
        colorcrist
        material
        sexo
        polarizacion
        calibre
        puente
        varilla
        calibre
        puente
        varilla
        locale
        marca
        modelo
        idarticulo
        images
        weight
        offerpriceeur
        offerpricegbp
        offerpriceusd
        listpriceeur
        listpricegbp
        listpriceusd
        crosssell
        meta {
          createdAt
        }
    }
    categories: allDatoCmsCategory(sort: {order: ASC, fields: sequence}, filter: {top: {eq: true}, locale: {eq: $locale}}) {
        edges {
            node {
                id
                name
                url
                sequence
                locale
                subcategory {
                    id
                    name
                    url
                    locale
                    sequence
                    subcategory {
                      id
                      name
                      url
                      locale
                      sequence
                    }
                }
            }
        }
    }
    contentPages: allDatoCmsContentPage(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                locale
                url
                cpContentGridTitle
            }
        }
    }
    allDatoCmsProduct(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                id
                descripcion
                descripcionampliada
                categoria
                categorias {
                    id
                    name
                    url
                    locale
                    sequence
                }
                locale
                marca
                modelo
                idarticulo
                images
                weight
                forma
                colormont
                colorcrist
                material
                calibre
                puente
                varilla
                offerpriceeur
                offerpricegbp
                offerpriceusd
                listpriceeur
                listpricegbp
                listpriceusd
                crosssell
            }
        }
    }
    allDatoCmsFooter(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                footerLinksList
                footerCopyright
                footerPayments
            }
        }
    }
    shipping: allDatoCmsLegalPage(filter: {locale: {eq: $locale}, name: {eq: "shipping"}}) {
        edges {
            node {
                legalPageUrl
                locale
                legalPageTitle
                legalPageContentWrapper {
                    legalPageTextContent
                }
            }
        }
    }
    contactinfo : datoCmsExtrainfo(name: {eq: "contactinfo"}, locale: {eq: $locale}) {
        data
    }
    newBadgeDays : datoCmsExtrainfo(name: {eq: "NewBadge_Constant"}, locale: {eq: $locale}) {
        data
    }
    ddm : allDatoCmsDdm(filter: {locale: {eq: $locale}}) {
        nodes {
            submenu {
                header
                directLink
                menu
            }
        }
    }
}
`

export default ProductPage