const axios = require('axios')
exports.getProduct = async (id) => {
    return axios.get(`https://app.snipcart.com/api/products/${id}`,{
        auth : {
            username : process.env.GATSBY_SNIPCART_SECRET_API_KEY,
            password : ''
        },
        headers: {
            'Accept' : 'application/json'
        }
    })
    .then ( response  =>  response.data.archived ? false : true )
    .catch ( error =>  false )
}
exports.deleteProduct = async (id) => {
    return axios.delete(`https://app.snipcart.com/api/products/${id}`, {
        auth : {
            username : process.env.GATSBY_SNIPCART_SECRET_API_KEY,
            password : ''
        },
        headers: {
            'Accept' : 'application/json'
        }
    })
    .then ( response  =>  response.status )
    .catch ( error =>  error.response.status )
}
exports.postProduct = async (url) => {
    return axios.post('https://app.snipcart.com/api/products', 
    {
        fetchUrl : `https://meninascambados.com/${url}`
    },
    {
        auth : {
            username : process.env.GATSBY_SNIPCART_SECRET_API_KEY,
            password : ''
        },
        headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then ( response  =>  response.status )
    .catch ( error =>  error.response.status )
}
exports.putStock = async (product) => {
    let { id, stock } = product
    let variants = []
    stock.forEach(size => {
        variants.push({            
            variation : [
                {
                    name : "Talla",
                    option : size.split(':', 1)[0]
                }
            ],
            stock : size.split(':', 2)[1],
            allowOutOfStockPurchases : false
        })
    })
    return axios.put(`https://app.snipcart.com/api/products/${id}`, 
    {
        inventoryManagementMethod : "Variant",
        variants : variants
    },
    {
        auth : {
            username : process.env.GATSBY_SNIPCART_SECRET_API_KEY,
            password : ''
        },
        headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then ( response  =>  response.status )
    .catch ( error =>  error.response.status )
}
exports.getStock = async (id) => {
    return axios.get(`https://app.snipcart.com/api/products/${id}`,{
        auth : {
            username : process.env.GATSBY_SNIPCART_SECRET_API_KEY,
            password : ''
        },
        headers: {
            'Accept' : 'application/json'
        }
    })
    .then (response => response.data.stock)
    .catch ( ()  =>  false )
}